import React from "react"
import styles from "../css/modules/promotion.module.css"

export default (props) => (
  <section className={`relative py-20 md:py-25 xl:py-26 ${styles.promotion} ${props.marginTop ? 'mt-20 md:mt-26' : ''} ${props.negativeMarginTop ? '-mt-10 md:-mt-16' : ''} ${props.marginBottom ? 'mb-20 md:mb-26' : ''}`}>
    <div className="container">
      <div className={`flex flex-wrap -mx-12 ${props.textCenter ? 'text-center' : 'text-left'}`}>
        {
          props.title &&
          <div className={`w-full px-12 ${props.textHtml ? 'md:w-1/2' : ''}`}>
            <h1 className="text-header-2-min sm:text-header-2-fluid xl:text-header-2-max text-white">{props.title}</h1>
          </div>
        }
        {
          props.textHtml &&
          <div className={`w-full mt-10 md:mt-0 px-12 text-white ${props.title ? 'md:w-1/2' : ''}`} dangerouslySetInnerHTML={{ __html: props.textHtml }}></div>
        }
      </div>
    </div>
  </section>
)
