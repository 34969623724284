import React from "react"
import styles from "../css/modules/promotion.module.css"

export default (props) => (
  <section className={`relative py-32 md:py-48 xl:py-64 ${styles.promotionSecondary} ${props.marginTop ? 'mt-20 md:mt-26' : ''} ${props.marginBottom ? 'mb-20 md:mb-26' : ''}`}>
    <div className="container">
      <div className="w-full md:w-1/2 markdown">
        {props.title && <h1 className="text-header-2-min sm:text-header-2-fluid xl:text-header-2-max text-white">{props.title}</h1>}
        {props.textHtml && <div className="text-white" dangerouslySetInnerHTML={{ __html: props.textHtml }}></div>}
      </div>
    </div>
  </section>
)
