import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Glide from '@glidejs/glide'
import '../../node_modules/@glidejs/glide/dist/css/glide.core.min.css'

export let glide = null

export default class Related extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentSlide: null }

    this.glideRef = React.createRef();
  }

  goTo(slide) {
      glide.go(`=${slide}`)
  }

  componentDidMount() {
    if(   
      typeof this.props.collection === 'object' && 
      (this.props.collection?.length ?? 0) > 0 
    ) {
      setTimeout(()=>{
        glide = new Glide(this.glideRef.current, {
          type: 'slider',
          rewind: false,
          perView: 3,
          gap: 48,
          bound: true,
          breakpoints: {
            1024: {
              perView: 1,
            }
          }
        }).mount()
        this.setState({currentSlide: glide.index});

        let self = this;
        glide.on(['run'], () => {
          self.setState({currentSlide: glide.index})
        })
        
      },30)
    }



  }

  componentWillUnmount() {
    if(   
      typeof this.props.collection === 'object' && 
      (this.props.collection?.length ?? 0) > 0 
    ) {
      glide.destroy();
    }
  }

  getUrl(typename, slug) {
    if (typename === "ContentfulPost") {
      return `/news/${slug}/`;
    }

    if (typename === "ContentfulProject") {
      return `/work/${slug}/`;
    }
  }

  render() {
    const { collection, title} = this.props

    return (
      <div
        style={{backgroundColor: this.props.backgroundColor, color: this.props.textColor}}
        className={`${this.props.marginTop ? 'mt-20 md:mt-26' : ''} ${this.props.marginBottom ? 'mb-20 md:mb-26' : ''}`}
      >
        <div className="container">
          <div className="border-t-4 border-black pt-16">

          <h3>{title || 'Related'}</h3>

          {collection && collection.length > 0 ? (
            <Fragment>
              <div ref={this.glideRef} className="glide mt-12">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    {
                      collection.map((item, index) => (
                      <li key={index} className="glide__slide">
                        <Link to={this.getUrl(item.__typename, item.slug)} className="group-hover">
                          {item.featuredImage && <Img className="mb-6" fluid={item.featuredImage.fluid} alt={item.title} />}
                          <h3 className="underline">{item.title}</h3>
                          {item.intro && <div className="mt-2">{item.intro.childMarkdownRemark.excerpt}</div>}
                        </Link>
                      </li>
                    ))}
                    </ul>
                </div>
              </div>

              <div className="lg:hidden flex items-center justify-center mt-8">
                {collection.map((dot, index) => (
                  <button
                    key={index}
                    onClick={this.goTo.bind(this, index)}
                    className={`w-2 h-2 bg-black mr-4 focus:outline-none ${this.state.currentSlide === index ? 'opacity-100' : 'opacity-25'}`}
                  ></button>
                ))}
              </div>
            </Fragment>
          ) : (
            console.warn('Did you provide a related collection?')
          )}

          </div>
        </div>
      </div>
    )
  }
}
