import React from "react"


export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.player = React.createRef()
    this.playerOptions = this.props.playerOptions || {}

    this.playerOptions.hideControls = false
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const Plyr = require("plyr")
      require("plyr/dist/plyr.css")
      const plyrPlayer = new Plyr(this.player.current, this.playerOptions || {})
      plyrPlayer.toggleControls(false);
      plyrPlayer.on('play', event => {
        plyrPlayer.toggleControls(true)
        if(!this.isIOS()) {
          plyrPlayer.config.hideControls = true
        }
      });
    }
  }

  isIOS() {
    if( navigator !== undefined) {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    return false
  }

  render() {
    return (
      <div className={`${this.props.useContainer ? 'container' : ''} ${this.props.marginTop ? 'mt-20 md:mt-26' : ''} ${this.props.marginBottom ? 'mb-20 md:mb-26' : ''}`}>
        <div
          id="player"
          ref={this.player}
          data-plyr-provider={this.props.videoProvider}
          data-plyr-embed-id={this.props.videoId}
        >
        </div>
      </div>
    )
  }
}