import React from "react"
import Image from "./image-svg-support"

export default (props) => {
  if (props.container) {
    return (
      <div
        style={{ backgroundColor: props.backgroundColor }}
        className={`${props.marginTop ? 'mt-20 md:mt-26' : ''} ${props.marginBottom ? 'mb-20 md:mb-26' : ''}`}
      >
        <div className="container">
          <Image fluid={props.fluid} svg={props.svg} file={props.file} alt="image" />
        </div>
      </div>
    )
  }

  return (
    <div className={`${props.marginTop ? 'mt-20 md:mt-26' : ''} ${props.marginBottom ? 'mb-20 md:mb-26' : ''}`}>
      <Image fluid={props.fluid} svg={props.svg} file={props.file} alt="image" />
    </div>
  )
} 
