import React, {useEffect, useRef, useState} from "react"
import styles from "../css/modules/hero-video.module.css"
import Image from "./image-svg-support"
import PlayButton from "../images/play.svg"

const VideoWrapperStyle = {
  position: 'relative',
  height: '110vh',
  overflow: 'hidden',
};

const VideoInnerStyle = {
  position: 'absolute',
  width: 'calc(110vh * (1920 / 1080))',  // video width / height
  height: 'calc(110vw * (1080 / 1920))',   // video height / width
  minWidth: '100%',
  minHeight: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
const PlayerInnerStyle = {
  position: 'absolute',
  width: '100%',  // video width / height
  height: '100%',   // video height / width
  minWidth: '100%',
  minHeight: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};



export default (props) => {

  const CloseButton = {
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    transform: 'scale(.8)',
    zIndex: 9999,
  };

  // For playbutton
  const player = useRef()
  const [playing, setPlaying] = useState(false)
  let PlyrPlayer = {}

  // If first element make room for menu
  if( props.index === 0) {
    CloseButton.top = 'calc(80px + 2rem)'; 
  }

  // Handle Playbutton 
  useEffect(()=>{
    if ( playing ) {
      require("plyr/dist/plyr.css")
      const Plyr = require("plyr")
      PlyrPlayer = new Plyr(player.current, {})
      PlyrPlayer.play()
    } else if ( PlyrPlayer.hasOwnProperty('playing') && PlyrPlayer.playing ) {
        PlyrPlayer.stop()
    }
  }, [playing, player])

  if (props.graphicOverlay && !props.showPlayButton) {
    return (
      <div style={VideoWrapperStyle}>
        <div style={VideoInnerStyle}>
          <video
            ref={player}
            className="w-full h-full absolute inset-0"
            poster={props.posterSrc}
            muted
            autoPlay
            playsInline
            loop
            preload="auto"
          >
            <source src={props.videoSrc} type="video/mp4" />
          </video>

          <div className={`fill-current ${styles.logo}`}>
            <Image
              svg={props.graphicOverlay.svg}
              file={props.graphicOverlay.file}
              alt="image"
            />
          </div>
        </div>
      </div>
    )
  }

  if (props.showPlayButton) {

    return (
      <div className="showreel" style={VideoWrapperStyle}>
        { !playing &&
          <div style={VideoInnerStyle}>
            <img src={props.posterSrc} style={VideoInnerStyle} alt='' />
            <div className={`fill-current ${styles.play}`}>
              <a 
                href="#play-video"
                onClick={(e)=>{
                    e.preventDefault()
                    setPlaying(true)
                  }}
                >
                <img src={PlayButton} alt="Play video" />
              </a>
            </div>
          </div>
        }
        { playing &&
          <div style={PlayerInnerStyle}>
              <button onClick={()=>{ setPlaying(false) }} className="relative focus:outline-none" style={CloseButton}>
                <svg className="w-10 h-10 fill-current text-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(.70710678 .70710678 -.70710678 .70710678 9.21967 -8.500893)"><path d="m28.6 11.6v1.5h-24v-1.5z"/><path d="m4.6 11.6h24v1.5h-24z" transform="matrix(.00000254 1 -1 .00000254 28.9926 -4.2856)"/></g></svg>
              </button>
            <video
              id="player"
              ref={player}
              className="w-full h-full absolute inset-0"
              poster={props.posterSrc}
              controls
              autoplay
              preload="auto"
            >
              <source src={props.videoSrc} type="video/mp4" />
            </video>
          </div>
        }
      </div>      
    )
  }

  return (
    <section
      style={{ backgroundColor: props.backgroundColor }}
      className={`${props.marginTop ? 'mt-20 md:mt-26' : ''} ${props.marginBottom ? 'mb-20 md:mb-26' : ''}`}
    >
      <div className={`${props.container ? 'container' : ''}`}>
        <video
          ref={player}
          poster={props.posterSrc}
          muted
          autoPlay
          playsInline
          loop
          preload="auto"
        >
          <source src={props.videoSrc} type="video/mp4" />
        </video>
      </div>
    </section>
  )
}