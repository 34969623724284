import React from "react"
import Image from "./image-svg-support"

export default (props) => {
  const { backgroundColor, bottomMedia, topMedia, standAloneMedia, marginBottom, marginTop, reverseOrderDesktop, reverseOrderMobile } = props

  return (
    <div
      style={{ backgroundColor: backgroundColor }}
      className={`${marginTop ? 'mt-20 md:mt-26' : ''} ${marginBottom ? 'mb-20 md:mb-26' : ''}`}
    >
      <div className="container">
        <div className={`flex flex-wrap -mx-12 flex items-center ${reverseOrderMobile ? 'flex-col-reverse' : 'flex-col'} ${reverseOrderDesktop ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
          {
            standAloneMedia &&
            <div className="w-full md:w-1/2 px-12">
              {
                standAloneMedia.file.contentType === 'video/mp4' ?
                <video muted playsInline autoPlay loop preload="auto">
                  <source src={standAloneMedia.file.url} type="video/mp4" />
                </video> :
                <Image fluid={standAloneMedia.fluid} svg={standAloneMedia.svg} file={standAloneMedia.file} alt="image" />
              }
            </div>
          }

          <div className={`w-full md:w-1/2 px-12 ${reverseOrderMobile ? 'mb-10' : 'mt-10'} md:m-0`}>
            {
              topMedia &&
              <div className="">
              {
                topMedia.file.contentType === 'video/mp4' ?
                <video muted playsInline autoPlay loop preload="auto">
                  <source src={topMedia.file.url} type="video/mp4" />
                </video> :
                <Image fluid={topMedia.fluid} svg={topMedia.svg} file={topMedia.file} alt="image" />
              }
              </div>
            }
            {
              bottomMedia &&
              <div className="mt-16 md:mt-24">
              {
                bottomMedia.file.contentType === 'video/mp4' ?
                <video muted playsInline autoPlay loop preload="auto">
                  <source src={bottomMedia.file.url} type="video/mp4" />
                </video> :
                <Image fluid={bottomMedia.fluid} svg={bottomMedia.svg} file={bottomMedia.file} alt="image" />
              }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
