import React from "react"
import Img from "gatsby-image"

export default (props) => {
  const { reverseMobile, reverseDesktop, title, textHtml, media, marginTop, marginBottom } = props
  const divStyles = {
    backgroundColor: props.backgroundColor,
    color: props.textColor
  }

  return (
    <section style={divStyles} className={`${marginTop ? 'pt-20 md:pt-26' : ''} ${marginBottom ? 'pb-20 md:pb-26' : ''}`}>
      <div className="container">
        <div className={`flex flex-wrap -mx-12 flex items-center ${reverseMobile ? 'flex-col-reverse' : 'flex-col'} ${reverseDesktop ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
          <div className="w-full md:w-1/2 px-12">
            <h2>{title}</h2>
            <div className="mt-2 markdown" dangerouslySetInnerHTML={{__html: textHtml}}></div>
          </div>
          {
            media &&
            <div className={`w-full md:w-1/2 px-12 ${reverseMobile ? 'mb-10' : 'mt-10'} md:m-0`}>
              {
                media.file.contentType === 'video/mp4' ?
                <video muted playsInline autoPlay loop preload="auto">
                  <source src={media.file.url} type="video/mp4" />
                </video> :
                <Img fluid={media.fluid} />
              }
            </div>
          }
        </div>
      </div>
    </section>
  )
}
