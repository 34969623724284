import React from "react"

export default (props) => {
  const { title, textHtml, largeText, textCentered } = props
  const divStyles = {
    backgroundColor: props.backgroundColor,
    color: props.textColor
  }

  return (
    <section style={divStyles} className={`${props.marginTop ? 'pt-20 md:pt-26' : ''} ${props.marginBottom ? 'pb-20 md:pb-26' : ''}`}>
      <div className="container">
        <div className="markdown">
          {
            title && textCentered &&
            <h2 className="text-center mb-4">{title}</h2>
          }
          <div className={`w-full ${largeText ? 'md:w-3/5 text-header-3-min sm:text-header-3-fluid xl:text-header-3-max' : 'md:w-1/2'} ${textCentered ? 'mx-auto text-center' : ''}`}>
            {
              title && ! textCentered &&
              <h2 className="mb-4">{title}</h2>
            }
            {textHtml && <div dangerouslySetInnerHTML={{ __html: textHtml }}></div>}
          </div>
        </div>
      </div>
    </section>
  )
}
