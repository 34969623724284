import React, { Fragment } from "react"
import HeroImage from "../components/hero-image"
import HeroVideo from "../components/hero-video"
import PlyrVideo from "../components/plyr-video"
import OneColumn from "../components/one-column"
import TwoColumns from "../components/two-columns"
import TwoColumnMedia from "../components/two-column-media"
import Promotion from "../components/promotion"
import PromotionSecondary from "../components/promotion-secondary"
import Related from "../components/related"

export default (props) => (
  <Fragment>
    {props.contentBlocks.map((section, index) => (
      <Fragment key={index}>
        {
          section.__typename === 'ContentfulHeroImage' &&
          <HeroImage
            container={section.container}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            backgroundColor={section.backgroundColor}
            fluid={section.image.fluid ? section.image.fluid : null}
            svg={section.image.svg ? section.image.svg : null}
            file={section.image.file ? section.image.file : null}
          />
        }
        {
          section.__typename === 'ContentfulHeroVideo' &&
          <HeroVideo
            videoSrc={section.video ? section.video.file.url : null}
            posterSrc={section.poster ? section.poster.resize.src : null}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            container={section.container}
            graphicOverlay={section.graphicOverlay}
            backgroundColor={section.backgroundColor}
            showPlayButton={section.showPlayButton}
            index={index}
          />
        }
        {
          section.__typename === 'ContentfulExternalVideo' &&
          <PlyrVideo
            videoProvider={section.videoProvider}
            videoId={section.videoId}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            useContainer={section.useContainer}
            backgroundColor={section.backgroundColor}
            index={index}
          />
        }
        {
          section.__typename === 'ContentfulPromotion' &&
          <Promotion
            title={section.title || null}
            textHtml={section.text ? section.text.childMarkdownRemark.html : null}
            negativeMarginTop={section.negativeMarginTop}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            textCenter={section.textCenter}
          />
        }
        {
          section.__typename === 'ContentfulPromotionSecondary' &&
          <PromotionSecondary
            title={section.title || null}
            textHtml={section.text ? section.text.childMarkdownRemark.html : null}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
          />
        }
        {
          section.__typename === 'ContentfulTwoColumn' &&
          <TwoColumns
            title={section.title}
            textHtml={section.body ? section.body.childMarkdownRemark.html: null}
            reverseMobile={section.reverseMobile}
            reverseDesktop={section.reverseDesktop}
            media={section.media}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            backgroundColor={section.backgroundColor}
            textColor={section.textColor}
          />
        }
        {
          section.__typename === 'ContentfulRelated' &&
          <Related
            collection={section.relatedItems}
            title={section.viewTitle}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            backgroundColor={section.backgroundColor}
            textColor={section.textColor}
          />
        }
        {
          section.__typename === 'ContentfulOneColumn' &&
          <OneColumn
            title={section.title || null}
            textHtml={section.text ? section.text.childMarkdownRemark.html : null}
            largeText={section.largeText}
            textCentered={section.textCentered}
            marginTop={section.marginTop}
            marginBottom={section.marginBottom}
            backgroundColor={section.backgroundColor}
            textColor={section.textColor}
          />
        }
        {
          section.__typename === 'ContentfulTwoColumnMedia' &&
          <TwoColumnMedia
            bottomMedia={section.bottomMedia ? section.bottomMedia : null}
            topMedia={section.topMedia ? section.topMedia : null}
            standAloneMedia={section.standAloneMedia ? section.standAloneMedia : null}
            marginBottom={section.marginBottom}
            marginTop={section.marginTop}
            reverseOrderDesktop={section.reverseOrderDesktop}
            reverseOrderMobile={section.reverseOrderMobile}
            backgroundColor={section.backgroundColor}
          />
        }
      </Fragment>
    ))}
  </Fragment>
)
